<template>
	<div class="reprint-container">
		<div v-if="options[0].label !== 'No print job'">
			<div class="reprint-item" v-if="options.length > 1">
				<label for="printJob">{{ $t('documentDetail.resendDocument.printJob') }}:</label>
				<div id="printJob">
					<v-select
						v-model="formData.printJob"
						:options='options'
						@click="clearError('printJob')"
					>
						<template #no-options="{search}">
							{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
						</template>
					</v-select>
				</div>

				<p v-if="errors.printJob" class="error">{{ $t('errors.printJob') }}</p>
			</div>
			<div class="reprint-item flex" v-if="displayWithAttachments">
				<label for="withAttachments">{{ $t('documentDetail.resendDocument.withAttachments') }}:</label>
				<input
					type="checkbox"
					class="customCheckbox"
					id="addAttachCheck"
					@click="askPopup"
					:checked="displayAttachmentsCheckbox"
				/>
			</div>

			<div class="reprint-item flex" v-if="displayRegisteredMail">
				<div class="reprint-item flex">
					<label for="withAttachments">{{ $t('documentDetail.resendDocument.registeredMail') }}:</label>
					<input
						type="checkbox"
						class="customCheckbox"
						id="registeredMail"
						v-model="registeredMail"
					/>
				</div>

				<div class="ml-4 reprint-item flex" v-if="registeredMail">
					<label for="withAttachments">{{
							$t('documentDetail.resendDocument.acknowledgementOfReceipt')
						}}:</label>
					<input
						type="checkbox"
						class="customCheckbox"
						id="acknowledgementOfReceipt"
						v-model="acknowledgementOfReceipt"
					/>
				</div>
			</div>

			<div>
				<div v-if="displayChangeAddress" class="reprint-item flex"
					 :style="errors.noAddress ? {marginBottom: 0} : ''">
					<label for="postalAddress">{{ $t('documentDetail.resendDocument.changeAddress') }}:</label>
					<input
						type="checkbox"
						class="customCheckbox"
						id="postalAddress"
						value="postalAddress"
						v-model="postalAddress"
					/>
				</div>
				<p v-if="errors.noAddress && !displayChangeAddress" class="error mb-1" style="margin-top: -5px">
					{{ $t('errors.noAddress') }}</p>
			</div>
			<div v-if="postalAddress || !displayChangeAddress">
				<div class="reprint-item" v-if="displayCompanyName">
					<label for='companyName'>{{ $t('documentDetail.resendDocument.postalAddress.companyName') }}</label>
					<input
						type="text"
						id='companyName'
						class="form-control text-popup"
						v-model="formData.postalAddress.companyName"
						@click="clearError('companyName')"
					/>
					<p v-if="errors.companyName" class="error">{{ $t('errors.requiredField') }}</p>
				</div>

				<div class="reprint-item" v-if="displayFullName">
					<label for="firstName">{{ $t('documentDetail.resendDocument.postalAddress.firstName') }}</label>
					<input
						type="text"
						id="firstName"
						class="form-control text-popup"
						v-model="formData.postalAddress.firstName"
					/>
				</div>
				<div class="reprint-item" v-if="displayFullName">
					<label for="lastName">{{ $t('documentDetail.resendDocument.postalAddress.lastName') }}</label>
					<input
						type="text"
						id="lastName"
						class="form-control text-popup"
						v-model="formData.postalAddress.lastName"
					/>
				</div>
				<div class="one-line">
					<div class="reprint-item">
						<label for='streetName'>{{
								$t('documentDetail.resendDocument.postalAddress.streetName')
							}}</label>
						<input
							type="text"
							id='streetName'
							class="form-control text-popup"
							v-model="formData.postalAddress.streetName"
							@click="clearError('street')"
						/>
						<p v-if="errors.street" class="error">{{ $t('errors.requiredField') }}</p>
					</div>

					<div class="reprint-item">
						<label for='houseNumber'>{{
								$t('documentDetail.resendDocument.postalAddress.houseNumber')
							}}</label>
						<input
							type="text"
							id='houseNumber'
							class="form-control text-popup"
							v-model="formData.postalAddress.houseNumber"
						/>
					</div>
				</div>
				<p class="error error-styling" v-if=errorAddressLength>{{ $t('errors.addressLength') }}</p>

				<div class="one-line">
					<div class="reprint-item">
						<label for='zip'>{{ $t('documentDetail.resendDocument.postalAddress.zip') }}</label>
						<input
							type="text"
							id='zip'
							class="form-control text-popup"
							v-model="formData.postalAddress.zip"
							@click="clearError('zip')"
						>
						<p v-if="errors.zip" class="error">{{ $t('errors.requiredField') }}</p>
						<p class="error" v-if=errorZipLength>{{ $t('errors.zipLength') }}</p>
						<p v-if="errors.zipBE" class="error">{{ $t('errors.zipBE') }}</p>
					</div>
					<div class="reprint-item">
						<label for='city'>{{ $t('documentDetail.resendDocument.postalAddress.city') }}</label>
						<input
							type="text"
							id='city'
							class="form-control text-popup"
							v-model.number="formData.postalAddress.city"
							@click="clearError('city')"
						/>
						<p v-if="errors.city" class="error">{{ $t('errors.requiredField') }}</p>
						<p class="error" v-if=errorCityLength>{{ $t('errors.cityLength') }}</p>
					</div>
				</div>

				<div class="reprint-item">
					<label for='countryCode'>{{ $t('documentDetail.resendDocument.postalAddress.countryCode') }}</label>
					<div class="country-code">
						<v-select
							id='countryCode'
							v-model="formData.postalAddress.countryCode"
							:options='countryCodeList'
							@click="clearError('countryCode')"
						>
							<template #no-options="{search}">
								{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
							</template>
						</v-select>
					</div>
					<p v-if="errors.countryCode" class="error">
						{{ $t('errors.requiredField') }}
					</p>
				</div>
			</div>
		</div>
		<div v-else>
				<p> {{$t('documentDetail.resendDocument.noPrintJob')}} support@nymus.be</p>
		</div>
		<div class="btn-content">
			<div>
				<button class="btn custom-button shadow-md mr-2 text-popup" @click="cancel">
					{{ $t('buttons.cancel') }}
				</button>
				<button
					v-if="options[0].label !== 'No print job'"
					class="btn custom-button shadow-md mr-2 text-popup"
					@click="resend"
					:disabled=disableButton
				>
					{{ $t('buttons.resend') }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import countryCodes from "../../../countryCodes"

export default {
	name: "ResendDocumentPrint",
	emits: ['closePopUp', 'cancel'],
	props: {
		docId: {
			type: Array
		},
		legalEntityId: {
			type: String
		},
		docsData: {
			type: Array
		}
	},
	mounted() {
		this.setAcknowledgement()
		this.setAddress()
	},
	data() {
		return {
			disableButton: false,
			maxAddressLength: 50,
			maxZipLength: 12,
			maxCityLength: 42,
			countryCodes: countryCodes,
			postalAddress: false,
			errors: {
				noAddress: false,
				printJob: false,
				street: false,
				zip: false,
				zipBE: false,
				city: false,
				countryCode: false,
				companyName: false,
			},
			withAttachments: true,
			registeredMail: true,

			// acknowledgementOfReceipt: false,
			acknowledgementOfReceipt: false,
			formData: {
				printJob: '',
				postalAddress: {
					firstName: '',
					lastName: '',
					streetName: '',
					houseNumber: '',
					zip: '',
					city: '',
					countryCode: {
						"label": "BE",
						"value": "BE"
					},
					companyName: "",
				}
			}
		}
	},
	watch: {
		"formData.postalAddress": {
			handler() {
				if (this.formData.postalAddress.companyName !== ""
					&& this.formData.postalAddress.streetName !== ""
					&& this.formData.postalAddress.zip !== ""
					&& this.formData.postalAddress.city !== ""
					&& this.formData.postalAddress.countryCode !== null) {
					this.disableButton = false
					this.errors.noAddress = false
				}
			},
			deep: true
		}
	},
	methods: {
		setAcknowledgement() {
			if (this.$store.state.documentDetailData.channels
				&& this.$store.state.documentDetailData.channels.print
				&& this.$store.state.documentDetailData.channels.print.acknowledgementOfReceipt) {
				this.acknowledgementOfReceipt = this.$store.state.documentDetailData.channels.print.acknowledgementOfReceipt
			}
		},
		askPopup() {
			const checkBox = document.getElementById("addAttachCheck")
			if (!this.displayAttachmentsCheckbox && checkBox.checked) {
				return this.$store.commit("displayAskPopUp", {display: true, action: 'resendDocumentMail'})
			}
			return null
		},
		clearError(error) {
			this.errors[error] = false
			this.errors.noAddress = false
			if (error === 'zip') {
				this.errors.zipBE = false
			}
		},
		setAddress() {
			if (this.$store.state.documentDetailData.channels && this.$store.state.documentDetailData.channels.print) {
				if (this.$store.state.documentDetailData.channels.print.postalAddress.firstName) {
					this.formData.postalAddress.firstName = this.$store.state.documentDetailData.channels.print.postalAddress.firstName
				}
				if (this.$store.state.documentDetailData.channels.print.postalAddress.lastName) {
					this.formData.postalAddress.lastName = this.$store.state.documentDetailData.channels.print.postalAddress.lastName
				}
				if (this.$store.state.documentDetailData.channels.print.postalAddress.companyName) {
					this.formData.postalAddress.companyName = this.$store.state.documentDetailData.channels.print.postalAddress.companyName
				}
				if (this.$store.state.documentDetailData.channels.print.postalAddress.streetName) {
					this.formData.postalAddress.streetName = this.$store.state.documentDetailData.channels.print.postalAddress.streetName
				}
				if (this.$store.state.documentDetailData.channels.print.postalAddress.houseNumber) {
					this.formData.postalAddress.houseNumber = this.$store.state.documentDetailData.channels.print.postalAddress.houseNumber
				}

				if (this.$store.state.documentDetailData.channels.print.postalAddress.city) {
					this.formData.postalAddress.city = this.$store.state.documentDetailData.channels.print.postalAddress.city
				}

				if (this.$store.state.documentDetailData.channels.print.postalAddress.zip) {
					this.formData.postalAddress.zip = this.$store.state.documentDetailData.channels.print.postalAddress.zip
				}
				if (this.$store.state.documentDetailData.channels.print.postalAddress.countryCode) {
					this.formData.postalAddress.countryCode = {
						"label": this.$store.state.documentDetailData.channels.print.postalAddress.countryCode,
						"value": this.$store.state.documentDetailData.channels.print.postalAddress.countryCode
					}
				}

				if (this.$store.state.documentDetailData.channels.print.printJob) {
					this.formData.printJob = {
						label: this.$store.state.documentDetailData.channels.print.printJob,
						value: this.$store.state.documentDetailData.channels.print.printJob,
					}

				}
			} else {
				if (this.$store.state.docType === "BILLING") this.setAddressBilling()

				if (this.$store.state.docType === "SALARY" && this.$store.state.documentDetailData.salaryData) {
					this.setAddressSalary()
				}
				if (this.$store.state.docType === "GENERAL") {
					this.setAddressGeneral()
				}
			}

			if (this.formData.printJob === undefined) {
				this.formData.printJob = {
					label: this.$store.state.supplierGroup.data.channelsConfig.print.printJobs[0].name,
					value: this.$store.state.supplierGroup.data.channelsConfig.print.printJobs[0].name
				}
			}
		},
		setAddressBilling() {
			if (this.$store.state.documentDetailData.billingData?.billingTo) {
				if (this.$store.state.documentDetailData.billingData.billingTo.customerIdentification) {

					if (this.$store.state.documentDetailData.billingData.billingTo.customerIdentification.firstName) {
						this.formData.postalAddress.firstName = this.$store.state.documentDetailData.billingData.billingTo.customerIdentification.firstName
					}
					if (this.$store.state.documentDetailData.billingData.billingTo.customerIdentification.lastName) {
						this.formData.postalAddress.lastName = this.$store.state.documentDetailData.billingData.billingTo.customerIdentification.lastName
					}
					if (this.$store.state.documentDetailData.billingData.billingTo.customerIdentification.companyName) {
						this.formData.postalAddress.companyName = this.$store.state.documentDetailData.billingData.billingTo.customerIdentification.companyName
					}
				}
				if (this.$store.state.documentDetailData.billingData.billingTo.customerAddress) {
					if (this.$store.state.documentDetailData.billingData.billingTo.customerAddress.streetName) {
						this.formData.postalAddress.streetName = this.$store.state.documentDetailData.billingData.billingTo.customerAddress.streetName
					}

					if (this.$store.state.documentDetailData.billingData.billingTo.customerAddress.houseNumber) {
						this.formData.postalAddress.houseNumber = this.$store.state.documentDetailData.billingData.billingTo.customerAddress.houseNumber
					}
					if (this.$store.state.documentDetailData.billingData.billingTo.customerAddress.zip) {
						this.formData.postalAddress.zip = this.$store.state.documentDetailData.billingData.billingTo.customerAddress.zip
					}

					if (this.$store.state.documentDetailData.billingData.billingTo.customerAddress.city) {
						this.formData.postalAddress.city = this.$store.state.documentDetailData.billingData.billingTo.customerAddress.city
					}
					if (this.$store.state.documentDetailData.billingData.billingTo.customerAddress.countryCode) {
						this.formData.postalAddress.countryCode = {
							"label": this.$store.state.documentDetailData.billingData.billingTo.customerAddress.countryCode,
							"value": this.$store.state.documentDetailData.billingData.billingTo.customerAddress.countryCode
						}
					}
				}
			} else if (this.$store.state.documentDetailData.mandateOnboardingData){
				let address = this.$store.state.documentDetailData.mandateOnboardingData.postalAddress
				this.formData.postalAddress.companyName = this.$store.state.documentDetailData.generalDocumentData.receiverName
				if (address.streetName) this.formData.postalAddress.streetName = address.streetName
				if (address.houseNumber) this.formData.postalAddress.houseNumber = address.houseNumber
				if (address.city) this.formData.postalAddress.city = address.city
				if (address.zip) this.formData.postalAddress.zip = address.zip
				if (address.countryCode) this.formData.postalAddress.countryCode = address.countryCode
			}
		},
		setAddressSalary() {
			if (this.$store.state.documentDetailData.salaryData.firstName) {
				this.formData.postalAddress.firstName = this.$store.state.documentDetailData.salaryData.firstName
			}
			if (this.$store.state.documentDetailData.salaryData.lastName) {
				this.formData.postalAddress.lastName = this.$store.state.documentDetailData.salaryData.lastName
			}
			if (this.$store.state.documentDetailData.salaryData.companyName) {
				this.formData.postalAddress.fcompanyName = this.$store.state.documentDetailData.salaryData.companyName
			}

			if (this.$store.state.documentDetailData.salaryData.streetName) {
				this.formData.postalAddress.streetName = this.$store.state.documentDetailData.salaryData.streetName
			}
			if (this.$store.state.documentDetailData.salaryData.houseNumber) {
				this.formData.postalAddress.houseNumber = this.$store.state.documentDetailData.salaryData.houseNumber
			}
			if (this.$store.state.documentDetailData.salaryData.zip) {
				this.formData.postalAddress.zip = this.$store.state.documentDetailData.salaryData.zip
			}
			if (this.$store.state.documentDetailData.salaryData.city) {
				this.formData.postalAddress.city = this.$store.state.documentDetailData.salaryData.city
			}
			if (this.$store.state.documentDetailData.salaryData.countryCode) {
				this.formData.postalAddress.countryCode = {
					"label": this.$store.state.documentDetailData.salaryData.countryCode,
					"value": this.$store.state.documentDetailData.salaryData.countryCode
				}
			}
			if (this.$store.state.documentDetailData.salaryData.companyName) {
				this.formData.postalAddress.companyName = this.$store.state.documentDetailData.salaryData.companyName
			}
		},
		setAddressGeneral() {
			if (this.$store.state.documentDetailData.carInsuranceData) {
				if (this.$store.state.documentDetailData.carInsuranceData.driverIdentification) {
					let name = this.$store.state.documentDetailData.carInsuranceData.driverIdentification.split(" ")
					this.formData.postalAddress.firstName = name[0];
					this.formData.postalAddress.lastName = name[1];
				}
			}
			if (this.$store.state.documentDetailData.generalDocumentData) {
				if (this.$store.state.documentDetailData.generalDocumentData.streetName) {
					this.formData.postalAddress.streetName = this.$store.state.documentDetailData.generalDocumentData.streetName
				}
				if (this.$store.state.documentDetailData.generalDocumentData.houseNumber) {
					this.formData.postalAddress.houseNumber = this.$store.state.documentDetailData.generalDocumentData.houseNumber
				}
				if (this.$store.state.documentDetailData.generalDocumentData.zip) {
					this.formData.postalAddress.zip = this.$store.state.documentDetailData.generalDocumentData.zip
				}
				if (this.$store.state.documentDetailData.generalDocumentData.city) {
					this.formData.postalAddress.city = this.$store.state.documentDetailData.generalDocumentData.city
				}
				if (this.$store.state.documentDetailData.generalDocumentData.countryCode) {
					this.formData.postalAddress.countryCode =
						{
							"label": this.$store.state.documentDetailData.generalDocumentData.countryCode,
							"value": this.$store.state.documentDetailData.generalDocumentData.countryCode
						}
				}
				if (this.$store.state.documentDetailData.generalDocumentData.receiverName) {
					this.formData.postalAddress.companyName = this.$store.state.documentDetailData.generalDocumentData.receiverName
				}
			}
		},
		cancel() {
			this.disableButton = false
			this.registeredMail = true
			this.acknowledgementOfReceipt = false
			this.$store.commit('displayResendDocument', false)
			this.$emit('cancel')
		},
		resend() {
			this.$store.commit("displayAskPopUp", {display: false, action: ''})
			this.disableButton = true
			this.validateForm()
			if (this.disableButton === false) {
				this.docsData.forEach(doc => {
					let formData = {}
					if (!this.postalAddress && this.displayChangeAddress) {
						if (this.formData.printJob.value) formData.printJob = this.formData.printJob.value
						else formData.printJob = this.formData.printJob
					} else {
						if (this.formData.printJob.value) this.formData.printJob = this.formData.printJob.value
						if (this.formData.postalAddress.countryCode.value) this.formData.postalAddress.countryCode = this.formData.postalAddress.countryCode.value
						formData = this.formData
					}
					if (this.$store.state.docType === "BILLING" || this.$store.state.docType === "GENERAL") {
						if (this.postalAddress) {
							delete formData.postalAddress.firstName
							delete formData.postalAddress.lastName
						}
					}
					if (this.$store.state.docType === "SALARY") {
						if (this.postalAddress) {
							delete formData.postalAddress.companyName
						}
					}
					if (this.displayWithAttachments) {
						formData.withAttachments = this.withAttachments
					} else {
						formData.withAttachments = false
					}
					if (this.displayRegisteredMail) {
						formData.registeredMail = this.registeredMail
						if (this.registeredMail) {
							formData.acknowledgementOfReceipt = this.acknowledgementOfReceipt
						}
					}

					let payload = {
						formData: formData,
						docId: doc.docId,
					}
					if (this.formData.printJob.value) {
						payload.formData.printJob = this.formData.printJob.value
					} else {
						payload.formData.printJob = this.formData.printJob
					}

					this.$store.dispatch('documents/reprintDocument', payload)
					this.disableButton = false

				})
				this.$emit('closePopUp')
			}

		},
		validateForm() {
			let hasErrors = false

			if (this.formData.printJob === '' || this.formData.printJob === null) {
				hasErrors = true;
				this.errors.printJob = true
			}

			if (this.postalAddress || !this.displayChangeAddress) {
				if (this.formData.postalAddress.streetName === '') {
					hasErrors = true
					this.errors.street = true
				}
				if (this.formData.postalAddress.zip === null || this.formData.postalAddress.zip === '') {
					hasErrors = true
					this.errors.zip = true
				}
				if (this.formData.postalAddress.countryCode.value === "BE") {
					let isnum = /^\d+$/.test(this.formData.postalAddress.zip)
					if (this.formData.postalAddress.zip.length !== 4 || !isnum) {
						hasErrors = true;
						this.errors.zipBE = true;
					}
				}
				if (this.formData.postalAddress.city === '') {
					hasErrors = true
					this.errors.city = true
				}
				if (this.formData.postalAddress.countryCode === null || this.formData.postalAddress.countryCode === '') {
					hasErrors = true
					this.errors.countryCode = true
				}
				if (this.formData.postalAddress.companyName === '') {
					hasErrors = true
					this.errors.companyName = true
				}
				if (this.errorAddressLength || this.errorZipLength || this.errorCityLength) {
					hasErrors = true
				}
			}
			this.disableButton = hasErrors
			this.errors.noAddress = hasErrors
			return hasErrors
		},
		disableData() {
			this.formData.printJob = "No print job"
			this.disableButton = true
		},

		setFormPrintJob(printJob) {
			this.formData.printJob = printJob
		},
		setPrintJob(orderedOption){
			this.formData.printJob = orderedOption
		}
	},

	computed: {
		displayChangeAddress() {
			return !(this.$store.state.documentDetailData.billingData?.billingTo === undefined && this.postalAddress === false)
		},
		displayAttachmentsCheckbox() {
			return !this.$store.state.documentDetailData.channels.archive.attachmentsMerged
		},
		displayRegisteredMail() {
			if (this.$store.state.documentDetailData.channels?.print?.registeredMail) {
				return this.$store.state.documentDetailData.channels.print.registeredMail
			}
			return false
		},
		displayWithAttachments() {
			return !!(this.$store.state.documentDetailData.fileStructure.attachmentsFileNames && this.$store.state.documentDetailData.fileStructure.attachmentsFileNames.length)
		},
		displayFullName() {
			return !(this.$store.state.docType === "BILLING" || this.$store.state.docType === "GENERAL")
		},
		errorCityLength() {
			return this.formData.postalAddress.city.length > this.maxCityLength
		},
		errorZipLength() {
			return this.formData.postalAddress.zip.length > this.maxZipLength
		},
		errorAddressLength() {
			return (this.formData.postalAddress.streetName.length + this.formData.postalAddress.houseNumber.length) > this.maxAddressLength
		},
		displayCompanyName() {
			return this.$store.state.docType !== "SALARY"
		},
		countryCodeList() {
			let countryCodes = [];
			for (let code in this.countryCodes) {
				let tempItem = {
					label: code,
					value: code,
				}
				countryCodes.push(tempItem)
			}
			return countryCodes
		},

		options() {
			let printJobsWithLe = []
			let printJobsNoLe = []
			let options = []

			if (this.$store.state.supplierGroup.data.channelsConfig.print && this.$store.state.supplierGroup.data.channelsConfig.print.printJobs) {
				let printJobs = this.$store.state.supplierGroup.data.channelsConfig.print.printJobs
				for (const printJob in printJobs) {
					let tempItemAllPrintJobs = {
						name: printJobs[printJob].name,
						legalEntityIds: []
					}
					if (printJobs[printJob].additional && printJobs[printJob].additional.legalEntityIds) {

						let tempItemPrintJobsWithLe = {
							name: printJobs[printJob].name,
							legalEntityIds: printJobs[printJob].additional.legalEntityIds
						}
						tempItemAllPrintJobs.legalEntityIds = printJobs[printJob].additional.legalEntityIds
						printJobsWithLe.push(tempItemPrintJobsWithLe);
					} else {
						let tempItemPrintJobsNoLe = {
							name: printJobs[printJob].name
						}
						printJobsNoLe.push(tempItemPrintJobsNoLe);
					}
				}
			}

			let printJobLinkedToDocLe = [];
			let entityNumber = this.$store.state.documentDetailData.legalEntity.entityNumber;

			printJobsWithLe.forEach(printJob => {
				printJob.legalEntityIds.forEach(le => {
					if (le === entityNumber) {
						printJobLinkedToDocLe.push(printJob.name)
					}
				})
			})
			if (printJobLinkedToDocLe.length === 0 && printJobsNoLe.length > 0) {
				for (let print in printJobsNoLe) {
					let tempItem = {
						label: printJobsNoLe[print].name,
						value: printJobsNoLe[print].name,
					}
					options.push(tempItem);
				}
			} else if (printJobLinkedToDocLe.length === 0 && printJobsNoLe.length === 0) {
				let tempItem = {
					label: 'No print job',
					value: '',
				}
				options.push(tempItem)
				this.setFormPrintJob('No print job')
				this.disableData()
			} else if (printJobLinkedToDocLe.length === 1) {
				let tempItem = {
					label: printJobLinkedToDocLe[0],
					value: printJobLinkedToDocLe[0],
				}
				options.push(tempItem)
				this.setFormPrintJob(printJobLinkedToDocLe[0])
			} else if (printJobLinkedToDocLe.length > 1) {
				let selectedPrintJob = ''
				printJobLinkedToDocLe.forEach(printJob => {
					let tempItem = {
						label: printJob,
						value: printJob,
					}
					options.push(tempItem)
					if (this.$store.state.documentDetailData.channels.print && this.$store.state.documentDetailData.channels.print.printJob && printJob === this.$store.state.documentDetailData.channels.print.printJob) {
						selectedPrintJob = printJob
					}
				})

				this.setFormPrintJob(selectedPrintJob)
			}

			let orderedLabels = []
			let orderedOptions = []

			options.forEach((option) => {
				orderedLabels.push(option.label)
			})
			orderedLabels.sort()

			orderedLabels.forEach((label) => {
				let tempItem = options.find(option => option.label === label)
				orderedOptions.push(tempItem)
			})
			if (orderedLabels.length === 1) this.setPrintJob(orderedOptions[0])

			return orderedOptions
		}
	}
}
</script>

<style scoped>
.btn-content {
	margin-top: var(--generalPaddings);
	padding-top: var(--generalPaddings);
	border-top: 1px solid var(--theme-primary-50);
}

.reprint-container {
	margin-top: var(--generalPaddings);
}

.reprint-item {
	display: grid;
	grid-template-columns: 160px auto;
	grid-template-rows: auto auto;
	grid-auto-flow: row;
	margin-bottom: var(--generalPaddings);
	align-items: center;
}

.reprint-item.flex {
	display: flex;
}

.reprint-item.flex label {
	margin-right: 6px;
}

.reprint-item p {
	grid-column: 2/3;
}

label {
	font-size: 14px;
}

.one-line {
	display: grid;
	grid-template-columns: auto auto;
	grid-template-rows: 100%;
	grid-auto-flow: column;
	grid-column-gap: 20px;
}

@media only screen and (max-width: 900px) {
	.one-line {
		grid-template-columns: 100%;
		grid-template-rows: auto auto;
	}
}

.country-code {
	width: 150px;
}

.error-styling {
	margin-left: 150px;
	margin-bottom: 10px;
	margin-top: -20px;
}
</style>