<template>
	<transition name="topAndBottomAnimation">
		<div class="header-height">
			<div>
				<div v-if="isStaging" class="staging" :class="{'showStaging' : isStaging}">
					STAGING
				</div>
				<header>
					<div class="headerLogo">
						<img :src='brandSettings.logoUrl' alt="company logo" @click="goToOverview"
							 style="cursor: pointer">
					</div>
					<nav>
						<div class="top-menu">
							<select @change="changeLocale" id="lang">
								<option v-for="lang in appLanguages" :value=lang.toLowerCase() :key="lang">
									{{ lang }}
								</option>
							</select>
							<div class="profile pointer"
								 @click="toggleProfile"
								 v-if="showAtPublic && userHasEmail && !SamlLogin">
								{{ $t('profile.title') }}
							</div>
						</div>
						<ul class="menu" :class="{styleOnLoginPage : styleOnLoginPage}" @click="this.$store.dispatch('updateToken')">
							<li
								class="pointer"
								v-if="showAtSecuredArea && acceptedTermsAndConditions && displayOverview"
							>
								<router-link :to=routerData.overview.link>{{ $t('overview.title') }}</router-link>
							</li>
							<li class="pointer"
								v-if="showAtSecuredArea && acceptedTermsAndConditions && showUbl"
							>
								<router-link :to=routerData.ubl.link>{{ $t('ubl.title') }}</router-link>
							</li>
							<li
								class="pointer"
								v-if="showAtSecuredArea && showPostDrop && acceptedTermsAndConditions"
							>
								<router-link :to=routerData.postDrop.link>{{ $t('postDrop.title') }}</router-link>
							</li>
							<li
								class="pointer"
								v-if="showAtSecuredArea && showPayments && acceptedTermsAndConditions"
							>
								<router-link :to=routerData.payments.link>{{ $t('payments.title') }}</router-link>
							</li>
							<li
								class="pointer"
								v-if="showAtSecuredArea && showManagement && acceptedTermsAndConditions"
							>
								<router-link :to=routerData.management.link>{{ $t('management.title') }}</router-link>
							</li>

							<li class="pointer" v-if="showAtSecuredArea && showReports && acceptedTermsAndConditions">
								<router-link :to=routerData.reports.link>{{ $t('reports.title') }}</router-link>
							</li>
							<li
								:class="{styleOnLoginPage : styleOnLoginPage}"
								class="pointer" v-if="displayExternalLink"
							>
								<a :href="externalLink.link" target="_blank">
									{{ externalLink.text }}
								</a>
							</li>
							<li
								:class="{styleOnLoginPage : styleOnLoginPage}"
								class="pointer"
								v-if="showHelpPage && userHasEmail && !SamlLogin"
							>
								<router-link :to=routerData.help.link>{{ $t('help.title') }}</router-link>
							</li>
							<li v-if="showIngestion">
								<router-link :to=routerData.ingestion.link>PDF reading</router-link>
							</li>
							<li
								class="pointer login-media"
								v-if="!showAtSecuredArea && showAtPublicLink"
								@click="redirectToLogin"
							>
								<div class="loginButton">{{ $t('buttons.login') }}</div>
							</li>
						</ul>
					</nav>
				</header>
				<reset-password>
				</reset-password>
				<transition name="dialog">
					<profile-settings
						@closeProfile="toggleProfile"
						v-if="showProfile"
						@editProfile="showEditProfile"
						@resetLoggedInUserPassword='showResetPassword'
					/>
				</transition>
				<transition name="dialog">
					<edit-profile
						@close=closeEditProfile
						v-show="editProfile"
					/>
				</transition>
			</div>
		</div>
	</transition>
</template>

<script>
import ProfileSettings from "./ProfileSettings.vue"
import routerData from "../routerData"
import {useI18n} from "vue3-i18n"
import EditProfile from "./EditProfile"
import userGroups from "../userGoups"
import ResetPassword from './management/users/ResetPassword.vue'
import store from "../store"
import router from "@/router"

const newLoginPage = window.newLoginPage
export default {
	name: "TheHeader",
	components: {EditProfile, ProfileSettings, ResetPassword},
	created() {
		this.getPublicDocumentData()
	},
	mounted() {
		this.setUserLang()
	},
	data() {
		return {
			routerData: routerData,
			userGroups: userGroups,
			showProfile: false,
			i18n: useI18n(),
			supplierGroupId: false,
			languages: [],
			editProfile: false,
			appLanguages: this.$store.state.supplierGroup.data.languages,
			externalLink: {
				text: '',
				link: ''
			}
		}
	},
	watch: {
		locale(newVal) {
			this.i18n.setLocale(newVal)
			this.setLang(newVal)
			document.getElementById('lang').setAttribute('value', newVal)
		},
		currentUser(newVal) {
			if (newVal.group === userGroups.OpHelpdesk.name
				|| newVal.group === userGroups.OpAdmin.name
				|| newVal.group === userGroups.ReceiverAdmin.name
				|| newVal.group === userGroups.SupplierGroupSuperAdmin.name) {
				this.showManagement = true
			}
		},
		SG(newVal) {
			this.appLanguages = newVal.languages.sort()
		}
	},
	computed: {
		showUbl() {
			// if peppol access is denied from the admin settings than we don't show the ubl tab
			if (!this.$store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.additionalPortalConfig?.peppol) return false

			// if your is accessType is NOT supplier or Optipost (op) than you don't have access to the ubl tab
			if (!(userGroups.supplierNames.includes(store.state.currentUserData.data.group) || userGroups.optipostNames.includes(store.state.currentUserData.data.group))) return false

			// if your role is supplierSubUser then you only have access to this tab when you have legal entities
			if (this.$store.state.currentUserData.data.group === "SupplierSubUser" && this.$store.state.currentUserData.data.legalEntities.length === 0) return false

			// if you get to this point you have access to the ubl tab
			return true
		},
		displayOverview() {
			// if billing access is denied from the admin settings than we don't show the overview tab
			if (!this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal?.additionalPortalConfig?.billing) return false

			// if you are a receiver and billing access is allowed than you have accass to see your documents
			if (this.$store.state.currentUserData.data.group === "ReceiverUser" || this.$store.state.currentUserData.data.group === "ReceiverAdmin") return true

			// if your is accessType is NOT supplier or Optipost (op) than you don't have access to the overview tab
			if (!(userGroups.optipostNames.includes(store.state.currentUserData.data.group) || userGroups.supplierNames.includes(store.state.currentUserData.data.group))) return false

			// if your role is supplierSubUser then you only have access to this tab when you have legal entities
			if ((this.$store.state.currentUserData.data.group === "SupplierSubUser" && this.$store.state.currentUserData.data.legalEntities.length === 0)) return false

			// if you get to this point you have access to the overview tab
			return true
		},
		styleOnLoginPage() {
			return this.$route.name === "login"

		},
		showIngestion() {
			return this.$store.state.currentUserData.data.email === "sven@nymus.be"

		},
		displayExternalLink() {
			if (this.$store.state.supplierGroup.data?.channelsConfig?.docImage?.configPortal?.externalPortalLinks
				&& Object.keys(this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.externalPortalLinks).length !== 0) {
				if (this.$store.getters.getLocale.toUpperCase()) {
					this.changeExternalLink()
				}
				return true
			}
			return false
		},
		showReports() {
			return (this.$store.state.currentUserData.data.group === this.userGroups.SupplierGroupSuperAdmin.name
				|| this.$store.state.currentUserData.data.group === this.userGroups.SupplierSubAdmin.name
				|| this.$store.state.currentUserData.data.group === this.userGroups.OpHelpdesk.name
				|| this.$store.state.currentUserData.data.group === this.userGroups.OpAdmin.name
			)
		},
		showPayments() {
			return (this.$store.state.currentUserData.data.group === this.userGroups.OpHelpdesk.name
					|| this.$store.state.currentUserData.data.group === this.userGroups.OpAdmin.name
					|| this.$store.state.currentUserData.data.group === this.userGroups.SupplierSubAdmin.name
					|| this.$store.state.currentUserData.data.group === this.userGroups.SupplierGroupSuperAdmin.name)
				&& (this.$store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.additionalPortalConfig?.showMandates)
		},
		showPostDrop() {
			if (this.$store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.additionalPortalConfig?.generalPost
				&& (userGroups.supplierNames.includes(store.state.currentUserData.data.group) || userGroups.optipostNames.includes(store.state.currentUserData.data.group))
			) {
				return this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig.generalPost
			}
			return false
		},
		showManagement() {
			return userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
				|| (this.$store.state.currentUserData.data.group === 'SupplierGroupSuperAdmin')
				|| (this.$store.state.currentUserData.data.group === 'SupplierSubAdmin')
				|| (this.$store.state.currentUserData.data.group === 'ReceiverAdmin')
		},
		currentUser() {
			return this.$store.state.currentUserData
		},
		isStaging() {
			return window.location.hostname.includes('localhost') || window.location.hostname.includes('staging')
		},
		locale() {
			return this.$store.state.locale
		},
		showAtPublic() {
			return this.$store.state.userIsAuthenticated
		},
		showHelpPage() {
			if (this.$store.state.userIsAuthenticated && this.$store.state.docType !== "SALARY") {
				return true
			}
			return this.$route.name === "login"
		},
		showAtSecuredArea() {
			return this.$store.state.userIsAuthenticated
		},
		acceptedTermsAndConditions() {
			return !(userGroups.receiverNames.includes(this.$store.state.currentUserData.data.group)
				&& this.$store.state.docType === "BILLING"
				&& !this.$store.state.currentUserData.data.termsAndConditionsAcceptedOn)
		},
		brandSettings() {
			return this.$store.state.supplierGroup.branding
		},
		showAtPublicLink() {
			let routeName = this.checkRootName()
			if (this.SG.channelsConfig) {
				if (routeName === 'public' && !(this.SG.channelsConfig.docImage.allowLogin)) {
					return false
				}
			}
			return !(routeName === 'onboarding'
				|| routeName === 'saml'
				|| routeName === 'login'
				|| routeName === 'channelChoice'
				|| routeName === 'channelChoiceAC'
				|| routeName === 'onboardingAC'
				|| routeName === 'createMandateOk'
				|| routeName === 'mandateOk'
				|| routeName === 'cancelMandateSuccessful'
				|| routeName === 'cancelMandateFail'
				|| routeName === 'onboardingSuccessful'
				|| routeName === 'onboardingUnsuccessful'
				|| routeName === 'cancelMandateCreate'
				|| routeName === 'proxyLogin')

		},
		SG() {
			return this.$store.state.supplierGroup.data
		},
		userHasEmail() {
			return this.$store.state.currentUserData.data.email !== null && this.$store.state.currentUserData.data.email !== ''
		},
		SamlLogin() {
			return (localStorage.getItem("saml") === "true" && this.$store.state.currentUserData.data.group === "ReceiverUser")
		}
	},

	methods: {
		updateToken() {
		},
		goToOverview() {
			router.push('/overview')
		},
		changeExternalLink() {
			if (this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.externalPortalLinks && this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.externalPortalLinks[this.$store.getters.getLocale.toUpperCase()]) {
				this.externalLink.text = this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.externalPortalLinks[this.$store.getters.getLocale.toUpperCase()].text
				this.externalLink.link = this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.externalPortalLinks[this.$store.getters.getLocale.toUpperCase()].link

			} else {
				if (this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.externalPortalLinks
					&& Object.keys(this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.externalPortalLinks).length !== 0) {
					this.externalLink.text = Object.values(this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.externalPortalLinks)[0].text;
					this.externalLink.link = Object.values(this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.externalPortalLinks)[0].link;
				}
			}
		},
		setUserLang() {
			if (this.$route.name === 'public' || this.$route.name === 'onboarding' || this.$route.name === "channelChoice") {
				this.$store.state.locale = this.$route.params.lang
			} else if (this.$store.state.currentUserData.data.lastLogin !== "") {
				this.$store.state.locale = this.$store.state.currentUserData.data.language.toLowerCase()
			} else this.$store.state.locale = 'en'
			if (this.$store.state.locale === '') this.$store.state.locale = 'en'
			this.setLang(this.$store.state.locale)
		},

		checkRootName() {
			if (this.$route.name === undefined) {
				setTimeout(() => {
					this.checkRootName()
				}, 50)
				return
			}
			return this.$route.name
		},
		getPublicDocumentData() {
			if (window.location.href.includes('localhost')) {
				this.$store.state.localHostUrl = true
			}
			if (window.location.href.includes('public')) {
				this.$store.state.publicDocumentId = window.location.href.split('/').pop().split('?')[0]
			}
		},
		redirectToLogin() {
			let href = ''
			if (this.$store.state.localHostUrl) {
				let url = window.location.href
				let host = window.location.hostname
				let protocol = window.location.protocol
				let url1 = url.replace(protocol, '').replace(window.location.hostname, '')
				let port = url1.split('/')[2]
				href = protocol + '//' + host + port + '/'
			}
			if (this.$store.state.publicDocumentId === '') {                                                                      //check if it is public link and redirect to home page
				if (this.$store.state.localHostUrl) {                                                                           //check if it is localhost the redirect to localhost
					document.location.href = href
				} else {
					document.location.href = this.$store.state.domainName + '/'                                                 //check if is not NOT localhost and redirect to domainName/
				}
			} else {                                                                                                                 //check if it is NOT public link and redirect to /overview/:docId page if it is not public link
				if (this.$store.state.localHostUrl) {
					document.location.href = href + 'overview/' + this.$store.state.publicDocumentId                //check if it is  localhost the redirect to localhost/overview/:docId
				} else {
					document.location.href = this.$store.state.domainName + '/overview/' + this.$store.state.publicDocumentId       //check if is not NOT localhost and redirect to domainName//overview/:docId
				}
			}
			if (newLoginPage) {
				this.$router.push('/login')
			}
		},
		closeEditProfile() {
			this.editProfile = false
		},
		showEditProfile() {
			this.editProfile = true
			this.showProfile = false
		},
		showResetPassword() {
			this.$store.commit('resetLoggedInUserPassword', true)
			this.$store.commit('displayResetPassword', true)
			this.showProfile = false
		},
		setLang(lang) {
			let langs = document.querySelector("#lang").options
			if (lang === null) langs = "EN"
			if (langs.length === 0) {
				setTimeout(this.setLang, 100, lang)
				return
			}
			let foundLanguage = false
			for (let i = 0; i < langs.length; i++) {
				if (langs[i].value === lang.toLowerCase()) {
					langs[i].selected = true
					foundLanguage = true
					return
				}
			}
			if (!foundLanguage) this.$store.state.locale = 'en'
		},
		changeLocale(event) {
			this.i18n.setLocale(event.target.value)
			this.$store.commit('setLocale', event.target.value)
		},
		toggleProfile() {
			this.$store.dispatch("updateToken")
			this.showProfile = !this.showProfile
		}
	}
}
</script>

<style scoped>
.header-height {
	min-height: var(--headerheight);
}

header {
	height: var(--headerheight)/2;
	display: grid;
	grid-template-columns: 0px calc(100% - 0px);
	grid-template-rows: 100%;
	grid-auto-flow: column;
}

.headerLogo {
	align-self: start;
	z-index: 2;
}

nav {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 50% 50%;
}

ul {
	margin: 0;
	padding: 0;
}

li {
	list-style: none;
}

li a, .loginButton {
	color: var(--theme-primary-500);
	padding: calc(0.5 * var(--generalPaddings)) calc(2.5 * var(--generalPaddings));
	text-decoration: none;
	border: 2px solid var(--theme-primary-10);
	border-radius: 6px;
}

li a:hover, .loginButton:hover {
	background-color: var(--theme-primary-10);
}

li a {
	white-space: nowrap;
}

.menu {
	height: 100%;
	justify-self: end;
	align-self: center;
	display: grid;
	grid-template-columns: auto auto auto;
	grid-auto-rows: 100%;
	grid-auto-flow: column;
	align-items: center;
	margin-top: 5px;
}

.top-menu {
	justify-self: end;
	align-self: center;
	padding: calc(0.5 * var(--generalPaddings)) calc(1.5 * var(--generalPaddings));
	display: grid;
	grid-template-columns: auto auto;
	grid-template-rows: 100%;
	grid-auto-flow: row;
	align-items: center;
	z-index: 3;
}

.profile {
	padding-left: calc(2 * var(--generalPaddings));
}

.pointer {
	cursor: pointer;
}

.top-menu select {
	background-color: white;
	border-radius: 4px;
	padding: calc(0.3 * var(--generalPaddings));
}

.staging {
	position: absolute;
	left: calc(50vw - 150px);
	font-weight: bold;
	color: var(--errorColor);
	align-self: center;
	font-size: 20px;
	border: 3px solid var(--errorColor);
	padding: 4px 30px;
	width: 150px;
}

.showStaging {
	opacity: 1;
}

.headerLogo img {
	max-width: unset;
	height: 50px;
}

@media only screen and (min-width: 1300px) {
	header {
		grid-template-columns: 10% 90%;
	}
}


@media only screen and (min-width: 501px) and (max-width: 870px) {
	.headerLogo img {
		height: 40px;
		max-width: unset;
	}
}

@media only screen and (min-width: 0px) and (max-width: 500px) {
	.headerLogo img {
		max-height: 35px;
		width: auto;
	}
}

@media only screen and (max-width: 850px) and (min-width: 721px) {
	.headerLogo img {
		max-height: 40px;
		max-width: unset;
	}

	nav {
		grid-column: 1/3;
		grid-row: 1/2;
	}

	.headerLogo {
		width: 80%;
		align-self: start;
		grid-column: 1/3;
		grid-row: 1/2;
	}
}

@media only screen and (max-width: 1300px) {
	nav {
		grid-column: 1/3;
		grid-row: 1/2;
	}

	.headerLogo {
		width: 80%;
		align-self: start;
		grid-column: 1/3;
		grid-row: 1/2;
	}

	li a {
		padding: calc(0.3 * var(--generalPaddings)) calc(0.5 * var(--generalPaddings));
	}
}

@media only screen and (max-width: 520px) {
	.top-menu {
		padding: 0 0 5px 0;
		padding-right: 5px;
	}

	.profile {
		adding-left: calc(0.5 * var(--generalPaddings));
	}
}

@media only screen and (max-width: 750px) {
	header {
		height: calc(1.3 * var(--headerheight));
	}

	nav {
		display: grid;
		grid-template-rows: 40% 60%;
		grid-template-columns: 100%;

	}

	.top-menu {
		grid-column: 1/2;
		grid-row: 1/2;
	}

	.menu {
		width: 100%;
		grid-column: 1/2;
		grid-row: 2/3;
		display: grid;
		grid-template-columns: auto auto auto auto;
		grid-template-rows: 50% 50%;
		justify-self: center;
		grid-auto-flow: row;
		justify-items: center;
	}
}

@media only screen and (max-width: 500px) {
	nav {
		grid-template-rows: 30% 70%;
	}

	.menu {
		grid-template-columns: auto auto auto;
		grid-template-rows: 33.33% 33.33% 33.33%;
	}

	li a {
		padding: calc(0.2 * var(--generalPaddings)) calc(0.3 * var(--generalPaddings));
	}
}

@media only screen and (max-width: 750px) {
	.styleOnLoginPage.menu {
		width: auto;
		justify-self: end;
	}

	.login-media {
		grid-column: 4/5;
		justify-self: end
	}
}
</style>